require("./scss/joinbutton.scss");

var PropTypes = require('prop-types');
var TremrUtils = require('../../utils/tremr_utils');
var classNames = require('../../utils/classnames');
var UserAwareMixin = require('../../mixins/userawaremixin');
var CreateReactClass = require('create-react-class');
var Config = require('config');
var alertify = require("alertify");

module.exports = CreateReactClass({

    mixins: [UserAwareMixin],

    propTypes: {
        channel: PropTypes.object.isRequired
    },

    getInitialState: function() {
        return {
            loading: false
        }
    },

    join: function() {
        this.setState({
            loading: true
        });
        let avatar = this.state.user.get('avatar_image');
        
        const data = {
            user_id: this.state.user.get('_id'),
            feeedname: this.state.user.get('feedname'),
            name: this.state.user.get('name'),
            channelname: this.props.channel.get('title'),
            channel: this.props.channel.get('name'),
            avatar_id: avatar ? avatar.public_id : '',
        };        
        
        this.props.liveDataPoints['request'].fbRef.set(data, function(error) {
            if (error) {
                console.log("Data could not be saved:");
                console.dir(error);
            }
        }.bind(this));

        // also send to server
        TremrUtils.fetch(`${Config.apiurl}/api/join/${this.props.channel.get('name')}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "same-origin",
        }).then(            
            function (response) {
                this.setState({
                    loading: false
                });
                if (response.ok) {
                    alertify.success("Requested to join " + this.props.channel.get('title'));
                } else {
                    console.log("Error:" + response.status + ":" + response.statusText);
                    alertify.error("Error requesting to join Blogcast");
                }
            }.bind(this)
        );
    },

    cancelJoin: function() {
        this.props.liveDataPoints['request'].fbRef.set(false, function(error) {
            if (error) {
                console.log("Data could not be saved:");
                console.dir(error);
            }
        }.bind(this));
    },

    handleClick: function() {

        let data = this.props.liveDataPoints['request'].data;
        if (data) {
            this.cancelJoin();
        } else {
            this.join();
        }
    },

    render: function() {

        // hide entirely if we are already a member
        let members = this.props.liveDataPoints['members'].data;
        if (members?.includes(this.state.user.get('_id'))) {
            return null;
        }                

        let data = this.props.liveDataPoints['request'].data;        

        // see if we have loaded the image or not
        let classes = {
            'join-button': true,
            'loading-ellipsis': this.state.loading,
        };
        classes = classNames(classes);

        let label = "Join";
        let title = "Click to request to join this Blogcast.";
        if (this.state.loading) {
            label = "Requesting";
            title = "Requesting";
        } else if (data) {
            label = "Pending";
            title = "Your join request is pending, click to withdraw your request.";
        }

        return <div className="join-button-container">
            <button className={classes} onClick={this.handleClick} title={title}>{label}</button>
        </div>;
    }
});